export const tableColumns = [
	{
		model: 'OplunitId',
		i18n: 'id2541',
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		model: 'OplunitNameSk',
		i18n: 'namesk',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'OplunitNameCz',
		i18n: 'namecz',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'OplunitNamePl',
		i18n: 'namepl',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'OplunitNameEn',
		i18n: 'nameen',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'OplunitNameDe',
		i18n: 'namede',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'OplunitNameHu',
		i18n: 'namehu',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'OplunitIsActive',
		i18n: 'active',
		sortable: true,
		filter: 'boolean',
	},
	{
		model: 'OplunitIsGeneral',
		i18n: 'generalpricelis',
		sortable: true,
		filter: 'boolean',
	},
	{
		model: 'OplunitIsAssigned',
		i18n: 'pricelistuj',
		sortable: true,
		filter: 'boolean',
	},
];
