<template>
	<table-page
		v-show="isLoaded"
		:tableName="$options.name"
		:columns="columns"
		:filterModel="filterModel"
		headerTitle="typesofpricesin"
		tooltipMsg="pmanualofpricet"
		:requestFunction="requestFunction"
		:defaultOrderBy="{ column: 'OplunitId', ascending: 0 }"
		modelId="OplunitId"
		@addNewRecord="redirectToDetail()"
		@editRecord="redirectToDetail($event)"
		@filterChanged="handleFilter($event)"
		@removeFilters="removeAllFilters($event)"
	>
		<template #OplunitIsActive="list">
			<boolean-display :boolValue="list.row.OplunitIsActive"></boolean-display>
		</template>

		<template #OplunitIsGeneral="list">
			<boolean-display :boolValue="list.row.OplunitIsGeneral"></boolean-display>
		</template>

		<template #OplunitIsAssigned="list">
			<boolean-display :boolValue="list.row.OplunitIsAssigned"></boolean-display>
		</template>
	</table-page>
</template>

<script>
import TablePage from '@/components/general/table-page';
import { tablePageMixin } from '@/components/general/table-page.mixin';
import * as links from '@/router/links';
import BooleanDisplay from '@/components/common/boolean-display';

import { tableColumns } from './price-list-unit-table.data';

export default {
	name: 'PriceListUnitTable',

	components: {
		TablePage,
		BooleanDisplay,
	},

	mixins: [tablePageMixin],

	data() {
		return {
			controllerName: 'PriceListUnit',
			columns: tableColumns,
		};
	},

	methods: {
		redirectToDetail(id = 0) {
			this.$router.push({
				name: links.priceListUnitDetailName,
				params: { id },
			});
		},
	},
};
</script>